/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DocumentType {
    INVOICE = 'INVOICE',
    DEBIT_NOTE = 'DEBIT_NOTE',
    CREDIT_NOTE = 'CREDIT_NOTE',
    PROFORMA = 'PROFORMA',
    ORDER = 'ORDER',
    PROTOCOL = 'PROTOCOL',
    NO_DOCUMENT = 'NO_DOCUMENT',
    RECEIPT = 'RECEIPT',
}
